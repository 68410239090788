import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import {AnalysisKeyService} from '@app/services/analysis-key.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token'
    })
  };

  constructor(private http: HttpClient,
              public analysisKeyService: AnalysisKeyService) { }

  getCategories(name: string = this.analysisKeyService.key() , curCategory: string = ''): Observable<any> {
    return this.http.get(curCategory.length ? `snap/categories/${name}/${curCategory}` : `snap/categories/${name}`);
  }

  getGraphsInfoPerCategory(name: string = this.analysisKeyService.key() ): Observable<any> {
    return forkJoin(
      this.http.get(`snap/reviewVolumePerBrand/${name}`),
      this.http.get(`snap/keywordFrequency/${name}`),
      this.http.get(`snap/varietyFeatureSentimentData/${name}`)
    );
  }

  getFilteredGraphsInfoPerCategory(name: string = this.analysisKeyService.key() , params = {}): Observable<any> {
    const tmpObj = {
      keyName: name,
      ...params
    };
    return forkJoin(
      this.http.post(`snap/filteredReviewVolumePerBrand`, tmpObj),
      this.http.post(`snap/filteredKeywordFrequency`, tmpObj),
      this.http.post(`snap/filteredVarietyFeatureSentimentData`, tmpObj)
    );
  }

  getAdditionalGraphsPerCategory(name: string = this.analysisKeyService.key() , params = {}): Observable<any> {
    const tmpObj = {
      keyName: name,
      ...params
    };

    return this.http.post(`snap/filteredVarietyFeatureSentimentData`, tmpObj);
  }

  getCommentForSentimentsByBrand(name: string = this.analysisKeyService.key(), params = {}): Observable<any> {
    const tmpObj = {
      keyName: name,
      ...params
    };
    return this.http.post(`snap/commentsForSentimentByBrandAndVariety`, tmpObj);
  }

  getNessFiltersPerCategory(name: string = this.analysisKeyService.key(), curCategory: string = ''): Observable<any> {
    return forkJoin(
      this.http.get(curCategory ? `snap/brands/${name}/${curCategory}` : `snap/brands/${name}`),
      this.http.get(`snap/summary/${name}`), // TODO: need to be replaced by dataSource
      // this.http.get(`snap/dataSource/${name}`)
      this.http.get(`snap/periods`)
    );
  }
}
