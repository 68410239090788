import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
// import * as d3.legend from 'd3-legend';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    //  initFunction();
  }

}

function initFunction() {
    const svg = d3.select("#viz")
    const container = svg.append("g");
    const width = 800
    const height = 600
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    // @ts-ignore
    d3.json('https://s3.amazonaws.com/speciate-data-graphing/graph.json').then(function(graph) {

        // @ts-ignore
        const graphLayout = d3.forceSimulation(graph.nodes)
        // @ts-ignore
            .force('charge', d3.forceManyBody().strength(-5000))
            // @ts-ignore
            .force('center', d3.forceCenter(width / 2, height / 2))
            // @ts-ignore
            .force('x', d3.forceX(width / 2).strength(1))
            // @ts-ignore
            .force('y', d3.forceY(height / 2).strength(1))
            // @ts-ignore
            .force('link', d3.forceLink(graph.links).id(function(d) {return d.id; })
                .distance(1).strength(1))
            .force('collision', d3.forceCollide().radius(function(d) {
                // @ts-ignore
                return d.radius * 2;
            }))
            .on('tick', ticked);

        const adjlist = [];

        graph.links.forEach(function(d) {
            adjlist[d.source.index + '-' + d.target.index] = true;
            adjlist[d.target.index + '-' + d.source.index] = true;
        });

        function neigh(a, b) {
            return a === b || adjlist[a + '-' + b];
        }

        svg.call(
            d3.zoom()
                .scaleExtent([.1, 4])
                .on('zoom', function() { container.attr('transform', d3.event.transform); })
        );


        const link = container.append('g').attr('class', 'links')
            .selectAll('line')
            .data(graph.links)
            .enter()
            .append('line')
            // @ts-ignore
            .attr('stroke-width', d => Math.sqrt(d.value) * 2);

        const node = container.append('g').attr('class', 'nodes')
            .selectAll('g')
            .data(graph.nodes)
            .enter()
            .append('circle')
            // @ts-ignore
            .attr('r', d => d.value * 2)
            // @ts-ignore
            .attr('fill', function(d) { return color(d.group); })

        const text = container.append('g')
            .attr('class', 'text')
            .selectAll('text')
            .data(graph.nodes)
            .enter().append('text')
            .attr('dy', '1em')
            // @ts-ignore
            .text(function(d) { return d.id; });

        svg.append('g')
            .attr('class', 'legendSequential')
            .attr('transform', 'translate(' + (50) + ',' + (30) + ')' );

/*        // @ts-ignore
        const legendSequential = d3.legendColor()
            .shapeWidth(30)
            .cells(3)
            .orient('vertical')
            .scale(color)

        svg.select('.legendSequential')
            .call(legendSequential);*/


        link.on('mouseover.tooltip', function(d) {
            tooltip.transition()
                .duration(300)
                .style('opacity', .8);
            // @ts-ignore
            tooltip.html('Connection: '+ d.source.id +
                // @ts-ignore
                '<br>Connection: ' + d.target.id +
                // @ts-ignore
                '<br>Count: '  + d.tooltip_count +
                '<br>Sentiment:')
                .style('left', (d3.event.pageX) + 'px')
                .style('top', (d3.event.pageY + 10) + 'px');
        })
            .on('mouseout.tooltip', function() {
                tooltip.transition()
                    .duration(100)
                    .style('opacity', 0);
            })
            .on('mousemove', function() {
                tooltip.style('left', (d3.event.pageX) + 'px')
                    .style('top', (d3.event.pageY + 10) + 'px');
            });
        ;

        node.on('mouseover.tooltip', function(d) {
            tooltip.transition()
                .duration(300)
                .style('opacity', 0.8);
            // @ts-ignore
            tooltip.html('Name: ' + d.id +
                // @ts-ignore
                '<br>Group: ' + d.tooltip_group +
                // @ts-ignore
                '<br>Count: ' + d.tooltip_count +
                // @ts-ignore
                '<br>Sentiment:')
                .style('left', (d3.event.pageX) + 'px')
                .style('top', (d3.event.pageY + 10) + 'px');
        })
            .on('mouseout.tooltip', function() {
                tooltip.transition()
                    .duration(100)
                    .style('opacity', 0);
            })
            .on('mousemove', function() {
                tooltip.style('left', (d3.event.pageX) + 'px')
                    .style('top', (d3.event.pageY + 10) + 'px');
            })
            .on('click', releaseNode)

        node.call(
            d3.drag()
                .on('start', dragstarted)
                .on('drag', dragged)
                .on('end', dragended)
        );

        node.on('mouseover', focus).on('mouseout', unfocus);

        var tooltip = d3.select('body')
            .append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0);

        function ticked() {
            node.call(updateNode);
            link.call(updateLink);
        }

        function fixna(x) {
            if (isFinite(x)) return x;
            return 0;
        }

        function focus(d) {
            // @ts-ignore
            var index = d3.select(d3.event.target).datum().index;
            node.style('opacity', function(o) {
                // @ts-ignore
                return neigh(index, o.index) ? 1 : 0.1;
            });
            link.style('opacity', function(o) {
                // @ts-ignore
                return o.source.index === index || o.target.index == index ? 1 : 0.1;
            });
        }

        function unfocus() {
            node.style('opacity', 1);
            link.style('opacity', 1);
        }

        function updateLink(link) {
            link.attr('x1', function(d) { return fixna(d.source.x); })
                .attr('y1', function(d) { return fixna(d.source.y); })
                .attr('x2', function(d) { return fixna(d.target.x); })
                .attr('y2', function(d) { return fixna(d.target.y); });
        }

        function updateNode(node) {
            node.attr('transform', function(d) {
                return 'translate(' + fixna(d.x) + ',' + fixna(d.y) + ')';
            });

            text.attr('transform', function(d) {
                // @ts-ignore
                return 'translate(' + fixna(d.x) + ',' + fixna(d.y) + ')';
            });
        }

        function releaseNode(d) {
            d.fx = null;
            d.fy = null;
        }

        function dragstarted(d) {
            if (!d3.event.active) {
                graphLayout.alphaTarget(0.3).restart();
            }
            d.fx = d.x;
            d.fy = d.y;
        }

        function dragged(d) {
            d.fx = d3.event.x;
            d.fy = d3.event.y;
        }

        function dragended(d) {
            if (!d3.event.active) {
                graphLayout.alphaTarget(0);
            }
        }

        function releasenode(d) {
            d.fx = null;
            d.fy = null;
        }

    }); // d3.json
}
