import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-request-access',
  templateUrl: './request-access.component.html',
  styleUrls: ['./request-access.component.scss']
})
export class RequestAccessComponent {

  requestForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
  ) {
    this.requestForm = formBuilder.group({
      'firstName': ['', [
        Validators.required
      ]],
      'lastName': ['', [
        Validators.required
      ]],
      'company': ['', [
        Validators.required
      ]],
      'email': ['', [
        Validators.required,
        Validators.email
      ]],
      'phone': ['', []],
      'comment': ['', []],
    });
  }

  sendRequest(): void {
    this.authService.sendRequest({
      firstName: this.requestForm.get('firstName').value,
      lastName: this.requestForm.get('lastName').value,
      company: this.requestForm.get('company').value,
      email: this.requestForm.get('email').value,
      phone: this.requestForm.get('phone').value,
      comment: this.requestForm.get('comment').value
    }).subscribe(
      () => this.router.navigate(['/dashboard']),
    );
  }
}
