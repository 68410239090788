import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MyHttpInterceptor } from './services/my-http-interceptor';
import { FormsModule } from '@angular/forms';

import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatButtonModule, MatCheckboxModule, MatExpansionModule} from '@angular/material';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule} from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FlipModule } from 'ngx-flip';

import { AppRoutingModule } from './app-routing.module';
import { DataService } from './services/data/data.service';

import { AuthService } from './services/auth/auth.service';
import { AuthGuard } from './services/guards/auth.guard';

import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './pages/layout.component';
import { MainMenuComponent } from './components/main-nav/main-nav.component';
import { FilterComponent } from './components/filter/filter.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';

import { LoginComponent } from './pages/login/login.component';
import { RequestAccessComponent } from './pages/request-access/request-access.component';
import { SummaryDashboardComponent } from './pages/summary-dashboard/summary-dashboard.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { BrandsComponent } from './pages/brands/brands.component';
import { DistributionComponent } from './pages/distribution/distribution.component';
import { CompetitorsComponent } from './pages/competitors/competitors.component';
import { TableComponent } from './components/table/table.component';
import { RatingComponent } from './components/rating/rating.component';
import { ChartComponent } from './components/chart/chart.component';
import { CommentsComponent } from './components/comments/comments.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { CustomChartComponent } from './components/custom-chart/custom-chart.component';
import { ClicksComponent } from './pages/clicks/clicks.component';
import { ChatsComponent } from './pages/chats/chats.component';
import {StopPropagationDirective} from '@components/stop-propagation';
import { NetworkComponent } from './pages/network/network.component';


@NgModule({
  declarations: [
    AppComponent,
    MainMenuComponent,
    LoginComponent,
    SummaryDashboardComponent,
    DefaultLayoutComponent,
    RequestAccessComponent,
    FilterComponent,
    CategoriesComponent,
    BrandsComponent,
    DistributionComponent,
    CompetitorsComponent,
    TableComponent,
    RatingComponent,
    ChartComponent,
    TopMenuComponent,
    CommentsComponent,
    CustomChartComponent,
    ClicksComponent,
    ChatsComponent,
      StopPropagationDirective,
      NetworkComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatTabsModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    NgxSpinnerModule,
    FlipModule,
    HighchartsChartModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    DataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
