import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnalysisKeyService {

  constructor() { }

  public key() {
    return 'EVENFLO';
  }
}
