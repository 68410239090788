import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AnalysisKeyService} from '@app/services/analysis-key.service';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token'
    })
  };

  constructor(private http: HttpClient,
              private analysisKeyService: AnalysisKeyService) {}

  // TODO: remove test name
  getSummary(name: string = this.analysisKeyService.key(), params = {}): Observable<any> {
    return this.http.get(`snap/summary/${name}`);
  }

  getSummaryByFilters(name: string = this.analysisKeyService.key(), params = {}): Observable<any> {
    const tmpObj = {
      keyName: name,
      ...params
    };
    return this.http.post(`snap/filteredSummary`, tmpObj, this.httpOptions);
  }

  // TODO: remove test name
  getSentimentScore(name: string = this.analysisKeyService.key(), params = {}): Observable<object> {
    return this.http.get(`snap/sentimentScore/${name}`);
  }

  // TODO: remove test name
  getKeywordFrequency(name: string = this.analysisKeyService.key(), params = {}): Observable<object> {
    return this.http.get(`snap/keywordFrequency/${name}`);
  }

  // TODO: remove test name
  getReviewVolumePerBrand(name: string = this.analysisKeyService.key(), params = {}): Observable<object> {
    return this.http.get(`snap//snap/reviewVolumePerBrand//${name}`);
  }

  // TODO: remove test name
  getReviewVolumePerCategory(name: string = this.analysisKeyService.key(), params = {}): Observable<object> {
    return this.http.get(`snap/sentimentScore/${name}`);
  }

  getCommentsForCategories(name: string = this.analysisKeyService.key(), category: string): Observable<object> {
    return this.http.get(`snap/commentsByCategory/${name}/${category}`);
  }
}
