import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';

@Injectable({
  providedIn: 'root'
})
export class HighchartsService {
    chartsForPages = {};

    constructor() {
    }

    createChart(container, chartName: string, options: any) {
        const divElement = document.createElement('div');
        console.log('OPTIONS: ', options);
        container.appendChild(divElement);
        if (!!options.chart) {
            options.chart['renderTo'] = divElement;
        } else {
            options.chart = {
                'renderTo': divElement
            };
        }
        this.chartsForPages[chartName] = new Highcharts.Chart(options);
    }

    remove(chartName: string) {
      if (!!this.chartsForPages[chartName]) {
          delete this.chartsForPages[chartName];

          console.log('Charts Pages: ', this.chartsForPages);
      }
    }

    getChart(chartName: string) {
        if (!!this.chartsForPages[chartName]) {
            return this.chartsForPages[chartName];
        }
        return undefined;
    }
}
