import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const fullUrl = `${environment.apiBase}/${req.url}`;
    const token = 'cb759b5b-39da-414c-be41-0cf160dfe377'; // TODO
    req = req.clone({ url: fullUrl, headers: req.headers.set('x-auth-token', token) });
    return next.handle(req);
  }
}
