import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ChartConfig } from '@app/types/types.class';
import { getRoundNumber, sortArrayByCount } from '@app/services/helper-functions';
import * as c3 from 'c3';
import * as d3 from 'd3';
import findKey from 'lodash.findkey';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit {
  @Input() chart;
  @Input() customLegend;
  @Input() graphsStore: any;
  @Input() chartClass: string;
  public chartConfig: ChartConfig = {
    chartId: 'chart1',
    type: 'bar',
    chartData: [
      ['Data1', 30, 200, 100, 400, 150, 250, 300],
      ['Data2', 50, 20, 10, 40, 15, 25, 80]
    ]
  };
  public mainConfig;

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.chartConfig = this.chart;
    this.cdRef.detectChanges();

    if (this.customLegend === true) {
      let chart = c3.generate(this.getCurrentConfig(this.chartConfig.chartData, false));
      const tmpArr = [];

      this.chartConfig.chartData.forEach(elem => {
        tmpArr.push(elem[0]);
      });
      d3.select(`.${this.chartClass} app-chart`).insert('div', '.chart').attr('class', 'legend').selectAll('span')
        .data(tmpArr)
        .enter().append('button')
        .attr('class', 'legend__item')
        .attr('data-id', id => id)
        .html(id => {
          const indexCompany = findKey(this.graphsStore.data, { company: id});

          const varieties = this.graphsStore.data[indexCompany].features[0].varieties;
          const markup = `
            <span class="legend__item-color" style="background-color: ${chart.color(id)}"></span>
            <span class="legend__item-name">${id}</span>
            `;
          return markup;
        })
        .each(function (id) {})
        .on('mouseover', function (id) {
          chart.focus(id);
        })
        .on('mouseout', function (id) {
          chart.revert();
        })
        .on('click', (id) => {
          const curElem = event.currentTarget as HTMLElement;
          const parentElem = <Element>curElem.parentNode;
          const curChart = document.querySelector(`.${this.chartClass} app-chart`);

          curChart.classList.add('is-single-chart');

          if (curElem.classList.contains('is-hide')) {
            curElem.classList.remove('is-hide');
          }

          curElem.classList.add('is-active');

          const siblingsElems = Array.from(parentElem.querySelectorAll('button'));

          siblingsElems.forEach(elem => {
            if (elem.dataset.id !== curElem.dataset.id) {
              elem.classList.remove('is-active');
              elem.classList.add('is-hide');
            }
          });
          chart.destroy();
          const indexCompany = findKey(this.graphsStore.data, {company: id});

          const curChartData = this.dataOfSentimentsFormating(this.graphsStore.data[indexCompany].features, this.graphsStore.features);

          chart = c3.generate(this.getCurrentConfig(curChartData, true, 'bottom'));
        });

      d3.select(`.${this.chartClass} app-chart`)
        .insert('a', '.chart')
        .attr('class', 'link-show-all')
        .attr('href', 'javascript: void(0);')
        .html('Show all')
        .on('click', (id) => {
          const legends = Array.from(document.querySelectorAll(`.${this.chartClass} app-chart .legend button`));
          const curChart = document.querySelector(`.${this.chartClass} app-chart`);

          curChart.classList.remove('is-single-chart');

          legends.forEach(elem => {
              elem.classList.remove('is-active');
              elem.classList.remove('is-hide');
          });
          chart.destroy();

          chart = c3.generate(this.getCurrentConfig(this.chartConfig.chartData, false));
        });
    } else {
      const chart = c3.generate(this.getCurrentConfig(this.chartConfig.chartData));
    }
    // this.cdRef.detectChanges();
  }

  private getCurrentConfig(data, showLegends = true, legendPosition = 'right') {
    return {
      bindto: `#${this.chartConfig.chartId}`,
      data: {
        columns: data,
        type: this.chartConfig.type,
        labels: true,
        axes: {
          data2: 'y2' // ADD
        }
      },
      color: this.chartConfig.color || { pattern: [] },
      axis: {
        x: {
          type: 'category',
          categories: this.chartConfig.axis.x.categories || [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        },
        y: this.chartConfig.axis.y || {}
      },
      legend: {
        position: legendPosition,
        show: showLegends
      },
      /* tslint:disable */
      ...this.chartConfig.addOptions // tslint:disable-line
      /* tslint:enable */
    };
  }

  private dataOfSentimentsFormating(data, features) {
    const tmpArr = [];
    sortArrayByCount(data, 'varieties');

    data[0].varieties.forEach((item, i) => {
      tmpArr.push([
        item.variety
      ]);
    });

    features.forEach(itemFeature => {
      const indexFeature = findKey(data, { feature: itemFeature });

      if (indexFeature !== undefined) {
        tmpArr.forEach(elem => {
          const indexVariety = findKey(data[indexFeature].varieties, { variety: elem[0] });
          if (indexVariety !== undefined) {
            elem.push(getRoundNumber(data[indexFeature].varieties[indexVariety].sentiment));
          } else {
            elem.push(0);
          }
        });
      } else {
        tmpArr.forEach(elem => {
          elem.push(0);
        });
      }
    });

    return tmpArr;
  }
}
