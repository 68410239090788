import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BrandsService } from '@app/services/data/brands.service';
import { HistoryNavigateService } from '@app/services/history-navigate.service';
import { getRoundNumber, getCurTimePeriod, getNecessaryFilters, MONTHS } from '@app/services/helper-functions';
import { NgxSpinnerService } from 'ngx-spinner';
import findKey from 'lodash.findkey';
import {AnalysisKeyService} from '@app/services/analysis-key.service';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit, OnDestroy {
  private curUrl: string;
  private timeout = 100;
  public activeMenu: string;
  public timePeriod = 365;
  public categoriesMenu;
  public currCategory = '';
  public filters: Array<any> = [];
  public queryParams = {
    brands: [
      {
        'name': ''
      }
    ],
    periodTypeId: 4
  };
  public filtersData = {
    categories: [],
    dataSource: [],
    periods: []
  };
  public chartPerCategory = {
    chartId: 'per-category',
    type: 'bar',
    chartData: [],
    axis: {
      x: {
        type: 'category',
        categories: []
      }
    }
  };
  public keyWords = {
    chartId: 'key-word',
    type: 'spline',
    chartData: [],
    axis: {
      x: {
        type: 'category',
        categories: []
      }
    }
  };
  public sentiments = {
    chartId: 'sentiments',
    type: 'bar',
    chartData: [],
    color: {
      pattern: [
        '#d62728',
        '#ff9896',
        '#9467bd',
        '#c5b0d5',
        '#8c564b',
        '#c49c94',
      ]
    },
    axis: {
      x: {
        type: 'category',
        categories: []
      },
      y: {
        max: 10,
        min: -10,
      }
    },
    addOptions: {
      grid: {
        y: {
          lines: [
            { value: 0, text: 'Label 0 for y' },
          ]
        }
      }
    }
  };

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private BrandsService: BrandsService,
              private spinner: NgxSpinnerService,
              private HistoryNavigateService: HistoryNavigateService,
              private analysisKeyService: AnalysisKeyService) { }

  ngOnInit() {
    this.spinner.show();
    const prevUrl = this.HistoryNavigateService.getPreviousUrl();

    if (this.HistoryNavigateService.getPreviousUrl() !== '/filter') {
      window.localStorage.removeItem('filters');
    }

    this.activatedRoute.queryParams.subscribe(params => {
      if (Object.keys(params).length && params.activeMenu) {
        this.activeMenu = params.activeMenu;
      } else {
        this.activeMenu = 'Baby Care';
      }
    });

    this.queryParams.brands[0].name = this.activeMenu;
    this.curUrl = this.activatedRoute.snapshot.url[0].path;

    this.queryParams = Object.assign(this.queryParams, JSON.parse(localStorage.getItem('filters')));

    this.BrandsService.getBrands(this.analysisKeyService.key() ).toPromise().then(
      categories => {
        this.categoriesMenu = categories;

        // TODO: put this data this.categoriesMenu[this.indexActiveMenu].name
        this.BrandsService.getNessFiltersPerBrand(this.analysisKeyService.key(), this.queryParams.brands[0].name)
          .subscribe(([categories, dataSource, periods]) => {
            this.filtersData = {
              categories: categories,
              dataSource: dataSource.dataSource,
              periods: periods
            };

            this.filters = getNecessaryFilters(this.filtersData, ['categories', 'dataSource', 'periods']);
            this.spinner.hide();
          });

        this.BrandsService.getFilteredGraphsInfoPerBrand(this.analysisKeyService.key(), this.queryParams).subscribe(([reviews, keywords, sentiments]) => {
          const curDates = this.queryParams ? getCurTimePeriod(this.queryParams.periodTypeId) : getCurTimePeriod();

          curDates.forEach((item) => {
            if (curDates.length === 7) {
              this.chartPerCategory.axis.x.categories.push(item.day);
              this.keyWords.axis.x.categories.push(item.day);
              this.sentiments.axis.x.categories.push(item.day);
            } else if (curDates.length === 30) {
              this.chartPerCategory.axis.x.categories.push(item.date);
              this.keyWords.axis.x.categories.push(item.date);
              this.sentiments.axis.x.categories.push(item.date);
            } else {
              this.chartPerCategory.axis.x.categories.push(item.month);
              this.keyWords.axis.x.categories.push(item.month);
              this.sentiments.axis.x.categories.push(item.month);
            }
          });

          this.sentiments.chartData = this.dataFormating(sentiments, curDates, 'feature');
          this.keyWords.chartData = this.dataFormating(keywords, curDates, 'keyword');
          this.chartPerCategory.chartData = this.dataFormating(reviews, curDates, 'category');

          setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
          }, this.timeout);
        });
      },
      err => {
        console.log(err);
      }
    );

  }

  ngOnDestroy(): void {
    // window.localStorage.removeItem('filters');
  }

  filterPage() {
    this.router.navigate(['/filter']).then(() => {
      this.router.navigate([this.curUrl], {
        queryParams: {
          'activeMenu': this.activeMenu
        }
      });
    });
  }

  private dataFormating(data, dates, title) {
    console.log('Data formatting: ', data);
    console.log('Data formatting dates: ', dates);
    const tmpArr = [];
    const typeOfDates = (dates.length === 7 || dates.length === 30) ? 'days' : 'months';

    data.forEach(item => {
      let activeDate: any = new Date(item.date);
      activeDate = typeOfDates === 'months' ? MONTHS[activeDate.getMonth()] : activeDate.getDate();
      const name = item[title].trim();
      const count = item.count;
      let indexDate: number;

      if (dates.length === 7 || dates.length === 30) {
        indexDate = +findKey(dates, { 'number': activeDate }) + 1;
      } else {
        indexDate = +findKey(dates, { 'month': activeDate }) + 1;
      }

      if (!this.hasElem(tmpArr, name)) {
        tmpArr.push([name]);
        const indexNote = findKey(tmpArr, { [0]: name });

        dates.forEach((item, i) => {
          tmpArr[indexNote][i + 1] = 0;
        });
      }

      const indexNote = findKey(tmpArr, { [0]: name });

      if (title === 'feature') {
        tmpArr[indexNote][indexDate] = +getRoundNumber(tmpArr[indexNote][indexDate] + item.score);
      } else {
        tmpArr[indexNote][indexDate] = tmpArr[indexNote][indexDate] + count;
      }
    });

    return tmpArr;
  }

  private hasElem(arr, name): boolean {
    let status = false;
    arr.forEach(elem => {
      if (elem.includes(name) && !status) {
        status = true;
      }
    });

    return status;
  }
}
