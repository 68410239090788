import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent} from './pages/login/login.component';
import { SummaryDashboardComponent } from './pages/summary-dashboard/summary-dashboard.component';
import { AuthGuard } from './services/guards/auth.guard';
import { DefaultLayoutComponent } from './pages/layout.component';
import { RequestAccessComponent } from './pages/request-access/request-access.component';
import {CategoriesComponent} from './pages/categories/categories.component';
import {BrandsComponent} from './pages/brands/brands.component';
import {DistributionComponent} from './pages/distribution/distribution.component';
import {CompetitorsComponent} from './pages/competitors/competitors.component';
import {ChatsComponent} from '@pages/chats/chats.component';
import {NetworkComponent} from '@pages/network/network.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'request', component: RequestAccessComponent },
  {
    path: '',
    component: DefaultLayoutComponent,
    /*canActivate: [AuthGuard],*/
    children: [
      { path: 'dashboard', component: SummaryDashboardComponent },
      { path: 'categories', component: CategoriesComponent },
      { path: 'brands', component: BrandsComponent },
      { path: 'distribution', component: DistributionComponent },
      { path: 'competitors', component: CompetitorsComponent },
        { path: 'network', component: NetworkComponent },
        { path: 'chats', component: ChatsComponent }
    ]
  },
  {
    path: 'filter', component: DefaultLayoutComponent
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload'})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
