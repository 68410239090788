import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HistoryNavigateService } from '@app/services/history-navigate.service';
import * as d3 from 'd3';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(private router: Router,
              private HistoryNavigateService: HistoryNavigateService) {}

  ngOnInit() {
    this.HistoryNavigateService.loadRouting();
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      if (this.HistoryNavigateService.getPreviousUrl() === undefined) {
        window.localStorage.removeItem('filters');
      }

      window.scrollTo(0, 0);
    });
  }
}
