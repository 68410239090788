import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
  private curUrl: string;
  @Input() menu;
  @Input() heading;
  @Input() activeMenu;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
  }

  filtered(event, name) {
    event.preventDefault();
    this.curUrl = this.activatedRoute.snapshot.url[0].path;

    this.router.navigate(['/filter']).then(() => {
      this.router.navigate([this.curUrl], {
        queryParams: {
          'activeMenu': name
        }
      });
    });
  }

}
