import {Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import { DataService } from '@app/services/data/data.service';
import { HistoryNavigateService } from '@app/services/history-navigate.service';
import { CompanySummary } from '@app/types/types.class';
import { getRoundNumber, getNecessaryFilters } from '@app/services/helper-functions';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, forkJoin } from 'rxjs';
import * as Highcharts from 'highcharts';
import * as _ from 'underscore';
import * as moment from 'moment';
import {AnalysisKeyService} from '@app/services/analysis-key.service';

@Component({
  selector: 'app-summary-dashboard',
  templateUrl: './summary-dashboard.component.html',
  styleUrls: ['./summary-dashboard.component.scss']
})
export class SummaryDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
    public Highcharts = Highcharts;
    private timeout = 100;
    public sentimentSeriesCompany = [];

    public starsArray = [];
    public sentimentArray = [];

    public viewSentimentGraph = true;
    public viewReviewVolumeGraph = true;
    public viewStarGraph = true;
    public sentimentSeries = [{
        name: '',
        data: this.sentimentArray
    },
        {
            name: '',
            data: this.sentimentArray
        }
    ];


    public colorArray = ['#ABEBC6', '#FF5733', '#036', '#06C', '#000'];

    public chartOptionsSentiment = {
        xAxis: {
            type: 'datetime',
            maxPadding: 0,
            endOnTick: false,
            startOnTick: false,
            title: {
                text: 'Date'
            }
        },
        chart: {
            type: 'spline'
        },
        title: {
            text: 'Sentiment'
        },
        yAxis: {
            title: {
                text: 'Sentiment'
            },
            min: -10,
            max: 10
        },
        subtitle: {
            text: 'Sentiment By Week Over Time'
        },
        plotOptions: {
            spline: {
                marker: {
                    enabled: true
                },
                gapSize: 0,
                gapUnit: 'relative'
            }
        },

        colors: this.colorArray,
        series: this.sentimentSeries
    };

    ngAfterViewInit() {
        window.setTimeout(() => {
        this.chartUpdateOne = true;
        this.chartUpdateTwo = true;
        this.chartUpdateThree = true;
        }, 200 );
    }

    public reviewArray = [];

    public reviewSeries = [{
        name: '',
        data: this.reviewArray
    },
        {
            name: '',
            data: this.reviewArray
        }
    ];

    public chartOptionsReview = {
        xAxis: {
            type: 'datetime',

            title: {
                text: 'Date'
            }
        },
        chart: {
            type: 'column'
        },
        title: {
            text: 'Review Volume'
        },
        yAxis: {
            title: {
                text: 'Review Volume'
            }
        },
        subtitle: {
            text: 'Volume of Reviews over Time'
        },
        plotOptions: {
            spline: {
                marker: {
                    enabled: true
                }
            }
        },

        colors: this.colorArray,
        series: this.reviewSeries
    };


    public starArray = [];

    public starSeries = [{
        name: '',
        data: this.starArray
    },
        {
            name: '',
            data: this.starArray
        }
    ];

    public chartOptionsStar = {
        xAxis: {
            type: 'datetime',

            title: {
                text: 'Date'
            }
        },
        chart: {
            type: 'scatter'
        },
        title: {
            text: 'Stars'
        },
        yAxis: {
            title: {
                text: 'Stars'
            },
            min: 0,
            max: 5
        },
        subtitle: {
            text: 'Average Stars by week over Time'
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: true,
                    symbol: 'triangle'
                }
            }
        },

        colors: this.colorArray,
        series: this.starSeries
    };

    public chartUpdateOne = false;
    public chartUpdateTwo = false;
    public chartUpdateThree = false;
    private curUrl: string;
    public filters: Array<any> = [];
    public summary: CompanySummary;
    public categoriesSortValue = 'rating';
    public categories: Array<any> = [];
    public commentsForCategories: any = [];

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private dataService: DataService,
                private spinner: NgxSpinnerService,
                private HistoryNavigateService: HistoryNavigateService,
                private analysisKeyService: AnalysisKeyService) {
    }

    ngOnInit() {

        this.activatedRoute.queryParams.subscribe(params => {
            this.spinner.show();
            this.curUrl = this.activatedRoute.snapshot.url[0].path;
            let queryParams = JSON.parse(localStorage.getItem('filters'));

            if (!queryParams) {
                queryParams = {
                    periodTypeId: 4
                };
            }

            if (this.HistoryNavigateService.getPreviousUrl() !== '/filter') {
                window.localStorage.removeItem('filters');
            }

            if (queryParams) {
                this.dataService.getSummaryByFilters(this.analysisKeyService.key(), queryParams).toPromise().then(
                    data => {
                        this.showContent(data);
                    },
                    err => {
                        console.log(err);
                    }
                );
            } else {
                this.dataService.getSummary().toPromise().then(
                    data => {
                        this.showContent(data);
                    },
                    err => {
                        console.log(err);
                    }
                );
            }
        });
    }

    filterPage() {
        this.router.navigate(['/filter']).then(() => {
            this.router.navigate([this.curUrl]);
        });
    }

    ngOnDestroy() {
    }

    private showContent(data): void {

        this.generateTimeSeriesData(data.timeSeries);

        this.summary = data.summary;

        this.filters = getNecessaryFilters(data, ['dataSource', 'periods']);

        if (!this.categories.length) {
            const tmpArr = [];
            data.categories.forEach((item, i) => {
                tmpArr.push(
                    this.dataService.getCommentsForCategories(this.analysisKeyService.key(), item.name)
                );
            });

            forkJoin(tmpArr).subscribe(comments => {
                data.categories.forEach((item, i) => {
                    this.categories.push({
                        flip: false,
                        comments: comments[i],
                        ...item
                    });

                });
                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                    this.spinner.hide();
                    console.log('RESIZE');
                }, this.timeout);
            }, err => {
                console.log(err);
            });
        }
    }

    public generateTimeSeriesData(timeSeries: any) {
        let sentimentOther = [];
        let sentimentCompany = [];

        const reviewOther = [];
        const reviewCompany = [];

        let starOther = [];
        let starCompany = [];

        let companyName = '';
        timeSeries = _.sortBy(timeSeries, (v) => {
            return Date.parse(v.review_date);
        });
        _.forEach(timeSeries, (v) => {
            if (v.company === 'OTHER') {
//                console.log('POINT: ',v);
  //              sentimentOther.push([Date.parse(v.review_date), v.sentiment*10]);
                reviewOther.push([Date.parse(v.review_date), v.reviewVolume]);
                starOther.push([Date.parse(v.review_date), v.stars]);
            } else {
                companyName = v.company;
                sentimentCompany.push([Date.parse(v.review_date), v.sentiment*10]);
                reviewCompany.push([Date.parse(v.review_date), v.reviewVolume]);
                starCompany.push([Date.parse(v.review_date), v.stars]);
            }
            //    this.sentimentArray.push([Date.parse(v.review_date), v.sentiment]);
        });


        const rawStarDataOther = [];
        const rawStarData = [];
        _.forEach(timeSeries, (v) => {
            if (v.company === 'OTHER') {
                rawStarDataOther.push(
                    {
                        reviewDate: (moment(new Date(v.review_date)).startOf('month')).valueOf(),
                        weekDate: (moment(new Date(v.review_date)).startOf('month')).format('MM-DD-YYYY'),
                        starsSum: v.stars * v.numberOfReviews,
                        numberOfReviews: v.numberOfReviews
                    });
            } else {
                rawStarData.push(
                    {
                        reviewDate: (moment(new Date(v.review_date)).startOf('month')).valueOf(),
                        weekDate: (moment(new Date(v.review_date)).startOf('month')).format('MM-DD-YYYY'),
                        starsSum: v.stars * v.numberOfReviews,
                        numberOfReviews: v.numberOfReviews
                    });
            }
        });


        starOther = _.map(
            // Group the sentiment data by week
            _.groupBy(rawStarDataOther, (val) => {
                return val.weekDate;
            })
            , (val) => {
                // Flatten the array to a single value
                return _.reduce(val, (returnValue, iteratedValue) => {
                    returnValue.starsSum += iteratedValue.starsSum;
                    returnValue.numberOfReviews += iteratedValue.numberOfReviews;
                    returnValue.stars = returnValue.starsSum / returnValue.numberOfReviews;
                    return returnValue;
                });
            });

        starCompany = _.map(
            // Group the sentiment data by week
            _.groupBy(rawStarData, (val) => {
                return val.weekDate;
            })
            , (val) => {
                // Flatten the array to a single value
                return _.reduce(val, (returnValue, iteratedValue) => {
                    returnValue.starsSum += iteratedValue.starsSum;
                    returnValue.numberOfReviews += iteratedValue.numberOfReviews;
                    returnValue.stars = returnValue.starsSum / returnValue.numberOfReviews;
                    return returnValue;
                });
            });

        starOther = _.map(starOther, (val) => {
            return { x: val.reviewDate, y: val.stars, name: 'Number of Reviews: '
                + val.numberOfReviews + '<br/>Week: ' + val.weekDate};
        });

        starOther = _.sortBy(starOther, (val) => {
            return val[0];
        });

        starCompany = _.map(starCompany, (val) => {
            return { x: val.reviewDate, y: val.stars, name: 'Number of Reviews: '
                + val.numberOfReviews + '<br/>Week: ' + val.weekDate};
        });

        starCompany = _.sortBy(starCompany, (val) => {
            return val[0];
        });

        const rawSentimentDataOther = [];
        const rawSentimentData = [];
        _.forEach(timeSeries, (v) => {
            if (v.company === 'OTHER') {
                rawSentimentDataOther.push(
                    {
                        reviewDate: (moment(new Date(v.review_date)).day(1)).valueOf(),
                        weekDate: (moment(new Date(v.review_date)).day(1)).format('MM-DD-YYYY'),
                        sentimentSum: v.sentiment * v.numberOfReviews,
                        numberOfReviews: v.numberOfReviews
                    });
            } else {
                rawSentimentData.push(
                    {
                        reviewDate: (moment(new Date(v.review_date)).day(1)).valueOf(),
                        weekDate: (moment(new Date(v.review_date)).day(1)).format('MM-DD-YYYY'),
                        sentimentSum: v.sentiment * v.numberOfReviews,
                        numberOfReviews: v.numberOfReviews
                    });
            }
        });


        sentimentOther = _.map(
            // Group the sentiment data by week
            _.groupBy(rawSentimentDataOther, (val) => {
                return val.weekDate;
            })
            , (val) => {
                // Flatten the array to a single value
                return _.reduce(val, (returnValue, iteratedValue) => {
                   returnValue.sentimentSum += iteratedValue.sentimentSum;
                   returnValue.numberOfReviews += iteratedValue.numberOfReviews;
                   returnValue.sentiment = returnValue.sentimentSum / returnValue.numberOfReviews;
                   return returnValue;
                });
        });

        sentimentCompany = _.map(
            // Group the sentiment data by week
            _.groupBy(rawSentimentData, (val) => {
                return val.weekDate;
            })
            , (val) => {
                // Flatten the array to a single value
                return _.reduce(val, (returnValue, iteratedValue) => {
                    returnValue.sentimentSum += iteratedValue.sentimentSum;
                    returnValue.numberOfReviews += iteratedValue.numberOfReviews;
                    returnValue.sentiment = (returnValue.sentimentSum / returnValue.numberOfReviews);
                    return returnValue;
                });
            });


        sentimentOther = _.map(sentimentOther, (val) => {
            return { x: val.reviewDate, y: val.sentiment * 10, name: 'Number of Reviews: '
                + val.numberOfReviews + '<br/>Week: ' + val.weekDate};
        });

        sentimentOther = _.sortBy(sentimentOther, (val) => {
            return val[0];
        });

        sentimentCompany = _.map(sentimentCompany, (val) => {
            return { x: val.reviewDate, y: val.sentiment * 10, name: 'Number of Reviews: '
                + val.numberOfReviews + '<br/>Week: ' + val.weekDate};
        });

        sentimentCompany = _.sortBy(sentimentCompany, (val) => {
            return val[0];
        });

        console.log('Sentiment Other', sentimentOther);
        console.log('Sentiment Company', sentimentCompany);

      //  console.log('FINAL: ', finalSentimentData);

        // [].push.apply(this.sentimentArray, sentimentCompany);


        this.reviewSeries.length = 0;
        this.reviewSeries.push({
            name: 'OTHER',
            data: reviewOther
        });

        this.reviewSeries.push({
            name: companyName,
            data: reviewCompany
        });

        this.sentimentSeries.length = 0;
        this.sentimentSeries.push({
            name: 'OTHER',
            data: sentimentOther
        });

        this.sentimentSeries.push({
            name: companyName,
            data: sentimentCompany
        });

        this.starSeries.length = 0;
        this.starSeries.push({
            name: 'OTHER',
            data: starOther
        });

        this.starSeries.push({
            name: companyName,
            data: starCompany
        });


        this.chartUpdateOne = true;
        this.chartUpdateTwo = true;
        this.chartUpdateThree = true;
    }

    getRoundValue(value, demical, mandatoryDemicalPoint, percent) {
        return getRoundNumber(value, demical, mandatoryDemicalPoint, percent);
    }

    public updateVolume($event) {
        this.viewReviewVolumeGraph = $event;
    }

    public updateSentiment($event) {
        this.viewSentimentGraph = $event;
    }


    public updateStar($event) {
        this.viewStarGraph = $event;
    }

}
