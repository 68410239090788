import {Component, OnInit, OnDestroy, ElementRef, ViewChild, ChangeDetectorRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CategoriesService } from '@app/services/data/categories.service';
import { HistoryNavigateService } from '@app/services/history-navigate.service';
import { getRoundNumber, getCurTimePeriod, getNecessaryFilters, MONTHS, sortArrayByName } from '@app/services/helper-functions';
import { NgxSpinnerService } from 'ngx-spinner';
import {Observable, forkJoin, Subject} from 'rxjs';
import findKey from 'lodash.findkey';
import forEach from 'lodash.foreach';
import {HighchartsService} from '@app/services/highcharts.service';
import {AnalysisKeyService} from '@app/services/analysis-key.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit, OnDestroy {
    @ViewChild('sentimentGraph') public chartSentiment: ElementRef;
    private curUrl: string;
    private timeout = 100;
    public activeMenu: string;
    public timePeriod = 365;
    public categoriesMenu;
    public currCategory = '';
    public filters: Array<any> = [];
    public sentimentsGraphsStore;
    public sentimentsComments;
    public additionalGraphs: any = [];
    public sentimentSubject = new Subject<any>();

    public rawSentimentData = new Array<any>();

    public queryParams = {
        categories: [
            {
                'name': ''
            }
        ],
        periodTypeId: 4
    };
    public filtersData = {
        brands: [],
        dataSource: [],
        periods: []
    };
    public chartPerBrand = {
        chartId: 'per-brand',
        type: 'bar',
        chartData: [],
        axis: {
            x: {
                type: 'category',
                categories: []
            }
        }
    };
    public keyWords = {
        chartId: 'key-word',
        type: 'spline',
        chartData: [],
        axis: {
            x: {
                type: 'category',
                categories: []
            }
        }
    };
    public sentiments = {
        chartId: 'sentiments',
        type: 'bar',
        chartData: [],
        axis: {
            x: {
                type: 'category',
                categories: []
            },
            y: {
                max: 10,
                min: -10,
            }
        },
        color: {
            pattern: [
                '#d62728',
                '#ff9896',
                '#9467bd',
                '#c5b0d5',
                '#8c564b',
                '#c49c94',
                '#FFF59D',
                '#FF9E80',
                '#9575CD',
                '#A1887F',
                '#26A69A',
                '#7CB342',
                '#F06292',
                '#616161',
                '#607D8B',
            ]
        },
        addOptions: {
            grid: {
                y: {
                    lines: [
                        {value: 0, text: 'Label 0 for y'},
                    ]
                }
            }
        }
    };

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private CategoriesService: CategoriesService,
                private spinner: NgxSpinnerService,
                private HistoryNavigateService: HistoryNavigateService,
                private HighchartsService: HighchartsService,
                private changeDetectionRef: ChangeDetectorRef,
                private analysisKeyService: AnalysisKeyService) {
    }

    ngOnInit() {
        this.spinner.show();

        if (this.HistoryNavigateService.getPreviousUrl() !== '/filter') {
            window.localStorage.removeItem('filters');
        }

        this.activatedRoute.queryParams.subscribe(params => {
            if (Object.keys(params).length && params.activeMenu) {
                this.activeMenu = params.activeMenu;
            } else {
                this.activeMenu = 'Activity Center';
            }

            this.queryParams.categories[0].name = this.activeMenu;
            this.curUrl = this.activatedRoute.snapshot.url[0].path;

            this.queryParams = Object.assign(this.queryParams, JSON.parse(localStorage.getItem('filters')));

            this.CategoriesService.getCategories(this.analysisKeyService.key()).toPromise().then(
                categories => {
                    console.log('Pulling CATEGORIES: ', categories);
                    this.categoriesMenu = categories;

                    // TODO: put this data this.categoriesMenu[this.indexActiveMenu].name
                    this.CategoriesService.getNessFiltersPerCategory(this.analysisKeyService.key(), this.queryParams.categories[0].name)
                        .subscribe(([brands, dataSource, periods]) => {
                                this.filtersData = {
                                    brands: brands,
                                    dataSource: dataSource.dataSource,
                                    periods: periods
                                };
                                this.filters = getNecessaryFilters(this.filtersData, ['brands', 'dataSource', 'periods']);
                            }, err => {
                                console.log(err);
                            }
                        );

                    this.CategoriesService.getFilteredGraphsInfoPerCategory(this.analysisKeyService.key(), this.queryParams)
                        .subscribe(([reviews, keywords, newSentiments]) => {
                                const curDates = this.queryParams ? getCurTimePeriod(this.queryParams.periodTypeId) : getCurTimePeriod();
                                console.log('NEW SENTIMENTS: ', newSentiments);

                                this.rawSentimentData = newSentiments.sentimentData;

                                curDates.forEach((item) => {

                                    if (curDates.length === 7) {
                                        this.chartPerBrand.axis.x.categories.push(item.day);
                                        this.keyWords.axis.x.categories.push(item.day);
                                    } else if (curDates.length === 30) {
                                        this.chartPerBrand.axis.x.categories.push(item.date);
                                        this.keyWords.axis.x.categories.push(item.date);
                                    } else {
                                        this.chartPerBrand.axis.x.categories.push(item.month);
                                        this.keyWords.axis.x.categories.push(item.month);
                                    }
                                });



                                this.sentimentsGraphsStore = this.preparationDataOfSentiments(newSentiments.sentimentData, 'company');
                                this.sentiments.axis.x.categories = this.sentimentsGraphsStore.features;

                                console.log('DATA: ', this.sentimentsGraphsStore);
                                const sentimentSubjectData: any = {
                                    data: this.sentimentsGraphsStore.data
                                };

                                forEach(this.sentimentsGraphsStore.data, (v) => {
                                    forEach(v.features, (f) => {
                                        f.sentiment = f.sentiment / f.sentimentCount;
                                    });
                                });

                              //  console.log('FEATURES: ', this.sentimentsGraphsStore.features);
                                this.sentiments.chartData = this.dataOfSentimentsFormating(this.sentimentsGraphsStore.data,
                                    this.sentimentsGraphsStore.features);

                                sentimentSubjectData.chartData = this.sentiments.chartData;

                                this.sentimentSubject.next({data: sentimentSubjectData, features: this.sentimentsGraphsStore.features, variety: newSentiments.sentimentData});

                                this.keyWords.chartData = this.dataFormating(keywords, curDates, 'keyword');
                                this.chartPerBrand.chartData = this.dataFormating(reviews, curDates, 'brand');

                                this.getAdditionalGraphs(this.sentimentsGraphsStore.features);

                                setTimeout(() => {
                                    window.dispatchEvent(new Event('resize'));
                                    this.spinner.hide();
                                }, this.timeout);
                            }, err => {
                                console.log(err);
                            }
                        );

                    this.CategoriesService.getCommentForSentimentsByBrand(this.analysisKeyService.key(), this.queryParams).toPromise()
                        .then(comments => {
                                this.sentimentsComments = this.commentsForSentimentsFormating(comments);
                            }, err => {
                                console.log(err);
                            }
                        );
                }, err => {
                    console.log(err);
                }
            );
        });
    }

    filterPage() {
        this.router.navigate(['/filter']).then(() => {
            this.router.navigate([this.curUrl], {
                queryParams: {
                    'activeMenu': this.activeMenu
                }
            });
        });
    }

    private dataFormating(data, dates, title) {
        const tmpArr = [];
        const typeOfDates = (dates.length === 7 || dates.length === 30) ? 'days' : 'months';

        data.forEach(item => {
            let activeDate: any = new Date(item.date);
            activeDate = typeOfDates === 'months' ? MONTHS[activeDate.getMonth()] : activeDate.getDate();
            const name = item[title].trim();
            const count = item.count;
            let indexDate: number;

            if (dates.length === 7 || dates.length === 30) {
                indexDate = +findKey(dates, {'number': activeDate}) + 1;
            } else {
                indexDate = +findKey(dates, {'month': activeDate}) + 1;
            }

            if (!this.hasElem(tmpArr, name)) {
                tmpArr.push([name]);
                const indexNote = findKey(tmpArr, {[0]: name});

                dates.forEach((item, i) => {
                    tmpArr[indexNote][i + 1] = 0;
                });
            }

            const indexNote = findKey(tmpArr, {[0]: name});

            if (title === 'feature') {
                tmpArr[indexNote][indexDate] = +getRoundNumber(tmpArr[indexNote][indexDate] + item.score);
            } else {
                tmpArr[indexNote][indexDate] = tmpArr[indexNote][indexDate] + count;
            }
        });

        return tmpArr;
    }

    private dataOfSentimentsFormating(data, features) {
        const tmpArr = [];

        data.forEach((item, i) => {
            tmpArr.push([
                item.company
            ]);

            features.forEach(itemFeature => {
                const indexFeature = findKey(item.features, {feature: itemFeature});
                if (indexFeature !== undefined) {
                    tmpArr[i].push(+getRoundNumber(item.features[indexFeature].sentiment));
                } else {
                    tmpArr[i].push(0);
                }
            });
        });
        console.log('Array: ', JSON.stringify(tmpArr));

        return tmpArr;
    }

    private commentsForSentimentsFormating(data) {
        const tmpArr = [];

        data.forEach((item, i) => {
            const indexCompany = findKey(tmpArr, {company: item.company.trim()});
            if (indexCompany === undefined) {
                tmpArr.push({
                    company: item.company,
                    varieties: [
                        {
                            variety: item.variety,
                            reviews: item.reviews
                        }
                    ]
                });
            } else {
                tmpArr[indexCompany].varieties.push({
                    variety: item.variety,
                    reviews: item.reviews
                });
            }
        });

        sortArrayByName(tmpArr, 'company');

        return tmpArr;
    }

    private preparationDataOfSentiments(data, type) {
        const tmpFeatures = [];
        const tmpData = [];

        console.log('SENTIMENT DATA: ', data);

        data.forEach(item => {
            if (tmpFeatures.indexOf(item.feature) === -1) {
                tmpFeatures.push(item.feature);
            }

            if (findKey(tmpData, {company: item.company}) === undefined) {
                tmpData.push({
                    company: item.company,
                    features: [
                        {
                            feature: item.feature,
                            sentiment: item.sentiment,
                            sentimentCount: 1,
                            varieties: [
                                {
                                    variety: item.variety,
                                    sentiment: item.sentiment
                                }
                            ]
                        }
                    ]
                });
            } else {
                const index = findKey(tmpData, {company: item.company});
                const indexFeature = findKey(tmpData[index].features, {feature: item.feature});

                if (indexFeature !== undefined) {
                    tmpData[index].features[indexFeature].sentiment = +tmpData[index].features[indexFeature].sentiment + +item.sentiment;
                    tmpData[index].features[indexFeature].sentimentCount++;
                    tmpData[index].features[indexFeature].varieties.push({
                        variety: item.variety,
                        sentiment: item.sentiment
                    });
                } else {
                    tmpData[index].features.push({
                        feature: item.feature,
                        sentiment: item.sentiment,
                        sentimentCount: 1,
                        varieties: [
                            {
                                variety: item.variety,
                                sentiment: item.sentiment
                            }
                        ]
                    });
                }
            }
        });

        sortArrayByName(tmpFeatures);
        sortArrayByName(tmpData, 'company');

        console.log('COMPANY DATA ARRAY: ', tmpData);

        return {
            data: tmpData,
            features: tmpFeatures
        };
    }

    private getAdditionalGraphs(features) {
        const tmpDataRequestArr = [];

        console.log('FEATURES BEING POULLED: ', features);
        console.log('FEATURES QUERY PARAMETERS: ', this.queryParams);
        features.forEach((item, i) => {
            tmpDataRequestArr.push(
                this.CategoriesService.getAdditionalGraphsPerCategory(this.analysisKeyService.key(), {
                    'features': [
                        item
                    ],
                    ...this.queryParams
                })
            );
        });

        forkJoin(tmpDataRequestArr).subscribe(data => {
            const tmpArr = [];

            data.forEach((item, i) => {
                tmpArr.push({
                    chart: {
                        chartId: `feature-${item.sentimentData[0].feature}`,
                        data: [item.sentimentData[0].feature],
                        categories: []
                    },
                    comments: []
                });

                sortArrayByName(item.reviews, 'company');
                sortArrayByName(item.sentimentData, 'company');

                item.sentimentData.forEach(elem => {

                    tmpArr[i].chart.data.push(getRoundNumber(elem.sentiment));
                    tmpArr[i].chart.categories.push(elem.company + '-' + elem.variety);
                });

                item.reviews.forEach(elem => {
                    tmpArr[i].comments.push({
                        variety: elem.company + '-' + elem.variety,
                        reviews: elem.reviews
                    });
                });
            });

            console.log('Additional Graphs: ', tmpArr);
            this.additionalGraphs = tmpArr;
            this.spinner.hide();
        }, err => {
            console.log(err);
        });
    }

    private getCurrentConfig(data) {
        return {
            chartId: data.chartId,
            type: 'bar',
            chartData: [data.data],
            labels: false,
            axis: {
                x: {
                    type: 'category',
                    categories: data.categories
                },
                y: {
                    max: 10,
                    min: -10,
                }
            },
            addOptions: {
                grid: {
                    y: {
                        lines: [
                            {value: 0, text: 'Label 0 for y'},
                        ]
                    }
                }
            }
        };
    }

    ngOnDestroy(): void {
        // window.localStorage.removeItem('filters');
    }

    private hasElem(arr, name): boolean {
        let status = false;
        arr.forEach(elem => {
            if (elem.includes(name) && !status) {
                status = true;
            }
        });

        return status;
    }

    public removeSentimentGraph() {
        this.HighchartsService.remove('sentimentGraph');
        if (!!document.getElementById('sentimentGraph').firstChild) {
          console.log('FOUND ELEMENT SENTIMENTGRAPH');
            // @ts-ignore
            document.getElementById('sentimentGraph').innerHTML = '';
        }
        this.changeDetectionRef.detectChanges();
    }

   /* public createSentimentGraph(myOpts: Object) {
      this.HighchartsService.createChart( this.chartSentiment.nativeElement, 'sentimentGraph', myOpts);
    }

    public addSentimentGraph() {
      this.removeSentimentGraph();
        const myCustomOptions = {
            chart: {
                type: 'bar'
            },
            title: {
                text: 'Stacked bar chart'
            },
            xAxis: {
                categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas']
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total fruit consumption'
                }
            },
            legend: {
                reversed: true
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: [{
                name: 'John',
                data: [5, 3, 4, 7, 2]
            }, {
                name: 'Jane',
                data: [2, 2, 3, 2, 1]
            }, {
                name: 'Joe',
                data: [3, 4, 4, 2, 5]
            }]
        };
        this.createSentimentGraph(myCustomOptions);
    }*/
}
