import {
    AfterViewInit,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ElementRef,
    ViewChild,
    ChangeDetectorRef
} from '@angular/core';
import * as Highcharts from 'highcharts';
import * as _ from 'underscore';
import {Subscription} from 'rxjs';
import {Feature} from 'geojson';
import {HighchartsService} from '@app/services/highcharts.service';

@Component({
    selector: 'app-custom-chart',
    templateUrl: './custom-chart.component.html',
    styleUrls: ['./custom-chart.component.scss']
})
export class CustomChartComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    public Highcharts = Highcharts;
    @ViewChild('customChartDiv') public customChart: ElementRef;
    @Input() sentimentDataSubject: any;
    subscription: Subscription;
    chartData: any;
    varietyChartData: any;
    finalGraphingData: Array<any>
    featureList: Array<FeatureDisplay>;
    varietyDisplay: Array<any>;
    hideList: Array<any>;
    features: Array<string>;
    varietyColors: Array<any>;

    hideAllCompanies: boolean;
    hideAllVarieties: boolean;

    public colorArray = ['#d62728',
        '#ff9896',
        '#9467bd',
        '#c5b0d5',
        '#8c564b',
        '#c49c94',
        '#FFF59D',
        '#FF9E80',
        '#9575CD',
        '#A1887F',
        '#26A69A',
        '#7CB342',
        '#F06292',
        '#616161',
        '#607D8B',
        '#ABEBC6',
        '#3cb44b',
        '#ffe119',
        '#4363d8',
        '#f58231',
        '#911eb4',
        '#46f0f0',
        '#f032e6',
        '#bcf60c',
        '#fabebe',
        '#008080',
        '#e6beff',
        '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080',
        '#ffffff', '#000000', '#8b391d', '#3cdf69', '#851da4', '#62b416',
        '#5243c7', '#a7d63c', '#3262ea', '#72ac00', '#ae42c8', '#31e27c', '#a4009a', '#00b241',
        '#db3fbe', '#93d950', '#e27cff', '#00bc61', '#b0008e', '#a0b000', '#517cff', '#d7c92f',
        '#0156cd', '#b9a000', '#9f8bff', '#639100', '#ff57c5', '#006d11', '#ff3fa1', '#00813c',
        '#be0085', '#a5d566', '#87288c', '#eac243', '#0081da', '#e68100', '#00a1fd', '#c93b00',
        '#32c1ff', '#d4003b', '#02ced9', '#fa2370', '#01ae83', '#de0079', '#01b89e', '#ff3d66',
        '#007a54', '#ff4370', '#1d5f17', '#ff75d5', '#4c7600', '#ec9dff', '#5a6300', '#d8a8ff',
        '#b88300', '#025baa', '#f9bc48', '#524796', '#ff9e39', '#6ea6ff', '#bc6200', '#0074b0',
        '#ff9a48', '#394f95', '#e4c35f', '#6e3c8f', '#8dd792', '#9d0d6e', '#88d79e', '#b7004f',
        '#bace80', '#614586', '#b7cf8e', '#b0001e', '#0188b4', '#ff5250', '#2f5d29', '#ffa4f0',
        '#415b0f', '#ff89c9', '#3f6940', '#ff5d81', '#5e5300', '#a0b2ff', '#9d250a', '#c9bff8',
        '#955b00', '#efb2f2', '#853f08', '#454f85', '#ff9160', '#855780', '#f8ba79', '#a31634',
        '#f1b3e5', '#694e21', '#ff83a9', '#ca9373', '#ff6f66', '#b3718c', '#ffa88d', '#ff9aa2'];

    public chartUpdate = false;

    public chartOptions = {
        xAxis: {
            type: 'datetime',

            title: {
                text: 'Date'
            }
        },
        chart: {
            type: 'spline'
        },
        title: {
            text: 'Stars'
        },
        yAxis: {
            title: {
                text: 'Stars'
            }
        },
        subtitle: {
            text: 'Stars over Time'
        },
        plotOptions: {
            spline: {
                marker: {
                    enabled: true
                }
            }
        },

        colors: this.colorArray,
        series: undefined
    };

    constructor(private HighchartsService: HighchartsService,
                private changeDetectionRef: ChangeDetectorRef) {
   //     console.log('IN Constructor');
        this.featureList = new Array<FeatureDisplay>();
        this.varietyDisplay = new Array<any>();
        this.hideList = new Array<any>();
        this.finalGraphingData = new Array<any>();
    }

    findColor(company, variety) {
  //      console.log('FIND COLOR: ', company, variety);
        let lookupName = company;
        if (variety) {
            lookupName += '/' + variety;
        }
        const returnColor = _.where(this.varietyColors, {name: lookupName});
        if (returnColor && returnColor.length > 0) {
            return returnColor.color;
        }
        return '';
    }

// [style.background]="findColor(company.company, variety.name)"

    initChart() {
        const graphingData = this.getInitialGraphData(this.varietyChartData);
        const varietyDisplay = new Array<any>();
        let counter = 0;
        this.varietyColors = [];
        _.forEach(this.chartData.data, (v) => {
            this.varietyColors.push({name: v.company, color: this.colorArray[counter]});
            v.color = this.colorArray[counter];
            v.varieties = this.extractVariety(v);
            v.hidden = false;
            _.forEach(v.varieties, (variety) => {
                // assign the graph colors
                counter++;
                variety.color = this.colorArray[counter];
                this.varietyColors.push({name: v.company + '/' + variety.name, color: this.colorArray[counter]});
                // pull the variety graph data
                const varietyData = _.findWhere(graphingData, {company: v.company, variety: variety.name});

                varietyData.data = this.getCompanySingleVarietyData(v, variety.name);
                variety.hidden = false;
            });
            counter++;
          //  console.log(v);
            varietyDisplay.push(v);
        });
        console.log('Variety Colors: ', this.varietyColors);
        this.varietyDisplay = varietyDisplay;
        this.finalGraphingData.length = 0;
        _.each(varietyDisplay, (value) => {
            const companyValue =  _.findWhere( graphingData, { company: value.company, variety: undefined });
            const companyColor = _.findWhere(this.varietyColors, {name: value.company });
            companyValue.color = companyColor.color;
            this.finalGraphingData.push(companyValue);
            _.each(value.varieties, (valueVariety) => {
                const varietyValue = _.findWhere( graphingData, { company: value.company, variety: valueVariety.name });
                 const varietyColor = _.findWhere(this.varietyColors, {name: value.company + '/' + valueVariety.name});
                 varietyValue.color = varietyColor.color;
                this.finalGraphingData.push(varietyValue);
            });
        });
        console.log('FINAL GRAPHING DATA: ', this.finalGraphingData);
        _.each(this.finalGraphingData, (finalData) => {
           finalData.name = finalData.company;
           if (finalData.variety) {
               finalData.name += '/' + finalData.variety;
           }
        });
    }

    formatVarietyChartData() {
        const returnValue = [];
        _.forEach(this.varietyChartData, v => {
            let currentValue = _.findWhere(returnValue,
                {
                    company: v.company,
                    variety: v.variety
                }
            );
            const indexValue = _.indexOf(this.features, v.feature);
            if (!currentValue) {
                currentValue = {
                    key: v.company + '/' + v.variety,
                    company: v.company,
                    variety: v.variety,
                    data: new Array(this.features.length)
                };
                currentValue.data.fill(0);
                returnValue.push(currentValue);
            }
            currentValue.data[indexValue] = v.sentiment;
        });
        return returnValue;
    }

    getInitialGraphData(data: any) {
        const arrayValue = [];
        _.forEach(data, v => {

            if (!_.findWhere(arrayValue, {company: v.company, variety: undefined})) {
               const addValue = {company: v.company, variety: undefined,
                   data: _.find(this.chartData.chartData, function(val) {return val[0] === v.company; }).slice(1)}
                arrayValue.push(addValue);
            }
            if (!_.findWhere(arrayValue, {company: v.company, variety: v.variety})) {
                const addValue = {company: v.company, variety: v.variety, data: []};
                arrayValue.push(addValue);
            }
        });
        return arrayValue;
    }

    getCompanySingleVarietyData(company: any, varietyName: string) {
        return this.featureGraphData(company, varietyName);
    }

    extractVariety(company: any) {
        const varieties = new Array<string>();
        const returnVarieties = new Array<any>();
        _.forEach(company.features, (v) => {
            _.forEach(v.varieties, (va) => {
                if (varieties.indexOf(va.variety) === -1) {
                    varieties.push(va.variety);
                    returnVarieties.push({name: va.variety});
                    this.featureGraphData(company, va.variety);
                }
            });
        });
        // console.log('VARIETIES: ', varieties);
        return returnVarieties;
    }


    featureGraphData(company, variety) {
      //  console.log('COMPANY: ', company.company, 'VARIETY: ', variety);
        const data = [];
        _.forEach(this.features, (f) => {
            const a = _.findWhere(company.features, {feature: f});
            if (a) {
                data.push(a.sentiment);
            } else {
                data.push(0);
            }
        });
     //   console.log('data: ',data);
        return data;
    }

    ngAfterViewInit() {

    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['sentimentDataSubject']) {
            this.subscription = this.sentimentDataSubject.subscribe(message => {
                this.chartData = message.data;
                this.features = message.features;
                this.varietyChartData = message.variety;
                this.initChart();
                this.removeSentimentGraph();
                this.addSentimentGraph();
            });
        }
    }

    public removeSentimentGraph() {
        this.HighchartsService.remove('customChartDiv');
        console.log('CHART ELEMENT: ', document.getElementById('customChartDiv'));
        if (!!document.getElementById('customChartDiv').firstChild) {
            console.log('FOUND ELEMENT SENTIMENTGRAPH');
            // @ts-ignore
            document.getElementById('customChartDiv').innerHTML = '';
        }
        this.changeDetectionRef.detectChanges();
    }

    public toggleCompany(company) {
        const theChart = this.HighchartsService.getChart('customChartDiv');
        const indexValue = _.findIndex(this.finalGraphingData,
            (indexSearchValue) => {
                if (indexSearchValue.company === company.company && indexSearchValue.variety === undefined) {
                    return true;
                }
                return false;
            });
        const series = theChart.series[indexValue];
        if (series.visible) {
            series.hide();
            company.hidden = true;
        } else {
            series.show();
            company.hidden = false;
        }
    }

    public toggleVariety(event, company, variety) {
        const theChart = this.HighchartsService.getChart('customChartDiv');
        const indexValue = _.findIndex(this.finalGraphingData,
            (indexSearchValue) => {
                console.log('Index Search Value: ', indexSearchValue, company.company);
                if (indexSearchValue.company === company.company && indexSearchValue.variety === variety.name) {
                    return true;
                }
                return false;
            });
        const series = theChart.series[indexValue];
        if (series.visible) {
            series.hide();
            variety.hidden = true;
        } else {
            series.show();
            variety.hidden = false;
        }
        return false;
    }

    toggleHideAllCompanies(hide) {
        console.log('Variety Data: ', this.varietyDisplay);
        const theChart = this.HighchartsService.getChart('customChartDiv');

            _.forEach(this.finalGraphingData, (value) => {
                console.log('VALUE: ', value);
                if (!value.variety) {
                    const indexValue = _.findIndex(this.finalGraphingData,
                        (indexSearchValue) => {
                            if (indexSearchValue.company === value.company && indexSearchValue.variety === undefined) {
                                return true;
                            }
                            return false;
                        });
                    const series = theChart.series[indexValue];
                    if (!hide) {
                        series.show();
                        this.changeHiddenField(value.company, undefined, false);
                    } else {
                        series.hide();
                        this.changeHiddenField(value.company, undefined, true);
                    }
                }
            });
            console.log('Graphing Data: ', this.finalGraphingData);

        this.hideAllCompanies = !this.hideAllCompanies;
    }

    changeHiddenField(company, variety, value) { // varietyDisplay
        const indexValue = _.findIndex(this.varietyDisplay,
            (indexSearchValue) => {
                if (indexSearchValue.company === company) {
                    return true;
                }
                return false;
            });
        if (!variety) {
            this.varietyDisplay[indexValue].hidden = value;
        } else {
            _.forEach(this.varietyDisplay[indexValue].varieties, (v) => {
               if (v.name === variety) {
                    v.hidden = value;
               }
            });
        }
    }

    toggleHideAllVarieties(hide) {
        console.log('Variety Data: ', this.varietyDisplay);
        const theChart = this.HighchartsService.getChart('customChartDiv');

        _.forEach(this.finalGraphingData, (value) => {
            console.log('VALUE: ', value);
            if (value.variety) {
                const indexValue = _.findIndex(this.finalGraphingData,
                    (indexSearchValue) => {
                        if (indexSearchValue.company === value.company && indexSearchValue.variety === value.variety) {
                            return true;
                        }
                        return false;
                    });
                const series = theChart.series[indexValue];
                if (!hide) {
                    series.show();
                    this.changeHiddenField(value.company, value.variety, false);
                } else {
                    series.hide();
                    this.changeHiddenField(value.company, value.variety, true);
                }
            }
        });
        console.log('Graphing Data: ', this.finalGraphingData);

        this.hideAllVarieties = !this.hideAllVarieties;
    }

    public createSentimentGraph(myOpts: Object) {
        console.log('Custom Chart: ', this.customChart);
        console.log('Options: ', myOpts);
        this.HighchartsService.createChart(this.customChart.nativeElement, 'customChartDiv', myOpts);
    }

    public addSentimentGraph() {
        this.removeSentimentGraph();
        const myCustomOptions = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Sentiment score by feature'
            },
            xAxis: {
                categories: this.features
            },
            yAxis: {
                min: 0,
                max: 10,
                title: {
                    text: 'Sentiment Score'
                }
            },
            legend: {
                enabled: false,
                reversed: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: this.finalGraphingData
        };
        this.createSentimentGraph(myCustomOptions);
    }
}

class FeatureDisplay {
    public color: string;
    public name: string;
    public visible: boolean;
    public indentation: string;
    public children: Array<FeatureDisplay>;
}
