import { Component, Input, Output, EventEmitter, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import findKey from 'lodash.findkey';
import * as _ from 'underscore';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})

export class FilterComponent implements OnInit {
  private tmpCategories: any;
  private tmpBrands: any;
  public showAll = false;
  public queryParams: any = {
    timePeriod: 4,
    dataSource: [],
    brands: [],
    categories: []
  };

  @Input() filtersArr: Array<any>;
  @Output() filtered = new EventEmitter<boolean>();

  constructor(private router: Router,
              private _elementRef: ElementRef,
              private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    let queryParams = JSON.parse(localStorage.getItem('filters'));

    const keyOfBrands = findKey(this.filtersArr, { 'title': 'Brands' });
    const keyOfCategories = findKey(this.filtersArr, { 'title': 'Categories' });

    if (keyOfBrands !== undefined) {
      this.tmpBrands = Object.assign({}, this.filtersArr[keyOfBrands].filters);
    }

    if (keyOfCategories !== undefined) {
      this.tmpCategories = Object.assign({}, this.filtersArr[keyOfCategories].filters);
    }

    console.log('QUERY PARAMS: ', queryParams);
    console.log('BRANDS: ', this.tmpBrands);
    console.log('CATEGORIES: ', this.tmpCategories);
    if (!queryParams) {
      queryParams = {};
      if (this.tmpBrands) {
          queryParams.brands = [];
          _.forEach(this.tmpBrands, (val) => {
            queryParams.brands.push(val);
          });
      }
        if (this.tmpCategories) {
            queryParams.categories = [];
            _.forEach(this.tmpCategories, (val) => {
                queryParams.categories.push(val.name);
            });
        }

    }
    this.checkUsedFilters(queryParams);
  }

  filter(form, event) {
    event.preventDefault();
    this.filterFunction();
  }

  filterFunction() {
    console.log('IN FILTER FUNCTION');
      window.localStorage.removeItem('filters');

      const tmbObj: any = {};

      tmbObj['periodTypeId'] = this.queryParams.timePeriod;
      if (this.isNotEmptyArr(this.queryParams.dataSource)) {
          tmbObj['dataSources'] = this.queryParams.dataSource;
      }

      if (this.isNotEmptyArr(this.queryParams.brands)) {
          tmbObj['brands'] = this.queryParams.brands;
      }

      if (this.isNotEmptyArr(this.queryParams.categories)) {
          tmbObj['categories'] = this.queryParams.categories;
      }
      console.log('Setting Filter Object', tmbObj);
      window.localStorage.setItem('filters', JSON.stringify(tmbObj));

      this.filtered.emit();
  }

  changePeriod(event) {
    if (event.value !== this.queryParams.timePeriod) {
      this.queryParams.timePeriod = event.value;
    }
  }

  showSubGroup(elem, event) {
    event.preventDefault();
    event.stopPropagation();
    const parentCheckbox = elem._elementRef.nativeElement;
    const btn = parentCheckbox.nextElementSibling;

    btn.classList.contains('is-opened') ? btn.classList.remove('is-opened') : btn.classList.add('is-opened');
  }

  addFilter(event, category, item) {
    if (category === 'Brands') {
      const currElem = item._elementRef.nativeElement;
      if (currElem.classList.contains('is-full-filled')) {
        currElem.classList.remove('is-full-filled');
        currElem.classList.remove('is-filled');
        item.checked = false;
        event.checked = false;
      }

      this.markNecessChildFilters(item);

      if (event.checked) {
        const keyOfBrand = findKey(this.tmpBrands, { 'name': event.source.name });
        const keyOfCurBrand = findKey(this.queryParams.brands, { 'name': item.name });

        if (keyOfCurBrand === undefined) {
          this.queryParams.brands.push({
            'name': this.tmpBrands[keyOfBrand].name,
            'varieties': this.tmpBrands[keyOfBrand].varieties
          });
        }
      } else {
        const index = findKey(this.queryParams.brands, { 'name': event.source.name });
        this.queryParams.brands.splice(index, 1);
      }
    } else {
      if (category === 'dataSource' && event.checked) {
        this.queryParams.dataSource.push(event.source.name);
      } else if (category === 'dataSource' && !event.checked) {

        const index = this.queryParams.dataSource.indexOf(event.source.name);
        this.queryParams.dataSource.splice(index, 1);
      }

      if (category === 'Categories' && event.checked) {
        this.queryParams.categories.push(event.source.name);
      } else if (category === 'Categories' && !event.checked) {

        const index = this.queryParams.categories.indexOf(event.source.name);
        this.queryParams.categories.splice(index, 1);
      }
    }
  }

  addSubFilter(event, item) {
    const parElem = item._elementRef.nativeElement;
    const subItems = parElem.parentNode.querySelectorAll('.checkbox-sub-items .mat-checkbox-input');
    const countChildElems = subItems.length;
    const keyOfBrand = findKey(this.tmpBrands, { 'name': item.name });

    if (event.checked) {
      if (this.queryParams.brands.length) {
        const keyOfCurBrand = findKey(this.queryParams.brands, { 'name': item.name });

        if (this.queryParams.brands[keyOfCurBrand] && this.queryParams.brands[keyOfCurBrand].varieties.length) {
          this.queryParams.brands[keyOfCurBrand].varieties.push(event.source.name);
        } else {
          this.queryParams.brands.push({
            'name': this.tmpBrands[keyOfBrand].name,
            'varieties': [event.source.name]
          });
        }
      } else {
        this.queryParams.brands.push({
          'name': this.tmpBrands[keyOfBrand].name,
          'varieties': [event.source.name]
        });
      }
    } else {
      const keyOfCurBrand = findKey(this.queryParams.brands, { 'name': item.name });
      const index = this.queryParams.brands[keyOfCurBrand].varieties.indexOf(event.source.name);

      this.queryParams.brands[keyOfCurBrand].varieties.splice(index, 1);
    }

    if (!parElem.classList.contains('is-filled') && event.checked) {
      parElem.classList.add('is-filled');

    } else if (parElem.classList.contains('is-filled') && !event.checked) {
      if (countChildElems === this.getStatusCurCheckboxArr(subItems, false)) {
        parElem.classList.remove('is-filled');
      }

      if (parElem.classList.contains('is-full-filled')) {
        parElem.classList.remove('is-full-filled');
      }
    }

    if (countChildElems === this.getStatusCurCheckboxArr(subItems)) {
      parElem.classList.add('is-full-filled');
    }
  }

  showMoreFilters(event) {
    event.preventDefault();
    event.stopPropagation();
    this.showAll = !this.showAll;
  }

  private markNecessChildFilters(item) {
    const elem = item._elementRef.nativeElement;
    const subItems = elem.parentNode.querySelectorAll('.checkbox-sub-items .mat-checkbox-input');

    if (subItems.length) {
      subItems.forEach(el => {

        if (item.checked === true && el.checked === false) {
          el.click();
        } else if (item.checked === false && el.checked === true) {
          el.click();
        }
      });
    }
  }

  private getStatusCurCheckboxArr(arr, checked: boolean = true) {
    let curCount = 0;

    arr.forEach(el => {
      if (checked && el.checked) {
        ++curCount;
      } else if (!checked && !el.checked) {
        ++curCount;
      }
    });

    return curCount;
  }

  private checkUsedFilters(queryParams) {
    // console.log('Checking used filters: ', queryParams);
    this.activatedRoute.queryParams.subscribe(params => {
      console.log('PARAMS: ', params);
      console.log('QUERY PARAMS: ', queryParams)
      console.log('OBJECTS: ', document
          .querySelectorAll('.js-brands .checkbox-sub-items .mat-checkbox-input') as HTMLCollectionOf<HTMLElement>);
      if (queryParams) {
        if (queryParams.periodTypeId) {
          this.queryParams.timePeriod = queryParams.periodTypeId;
        }

        if (queryParams.categories) {
          setTimeout(() => {
            const keyOfCategories = findKey(this.filtersArr, { 'title': 'Categories' });
            const curFilters = document.querySelectorAll('.js-categories .mat-checkbox-input') as HTMLCollectionOf<HTMLElement>;


            queryParams.categories.forEach(item => {
              const indexCategory: number = findKey(this.filtersArr[keyOfCategories].filters, { 'name': item });
              const elem = curFilters[indexCategory];
              elem.click();
            });
          }, 100);
        }

        if (queryParams.brands) {
          setTimeout(() => {
            const keyOfCategories = findKey(this.filtersArr, { 'title': 'Brands' });
            const curFilters = document
                              .querySelectorAll('.js-brands .checkbox-sub-items .mat-checkbox-input') as HTMLCollectionOf<HTMLElement>;
            console.log('Current Filters: ', curFilters);

            queryParams.brands.forEach(item => {
              console.log('ITEM: ', item.name);
              item.varieties.forEach(element => {
                const indexCategory: number = findKey(curFilters, { 'id': item.name + '-' + element + '-input' });
                const elem = curFilters[indexCategory];
                elem.click();
              });
            });
          }, 100);
        }
      }

    });
  }

  private isNotEmptyArr (arr) {
    return arr.length > 0 ? true : false;
  }
}
