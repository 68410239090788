import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  @Input() tabs;
  @Input() singleLevel;
  @Input() featureName;
  public activatedTabs = false;
  constructor() { }

  ngOnInit() {}

  activateTabs() {
    if (!this.activatedTabs) {
      this.activatedTabs = true;
    }
  }
}
