import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  loginForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
  ) {
    this.loginForm = formBuilder.group({
      'userName': ['', [
        Validators.required
      ]],
      'userPass': ['', [
        Validators.required
      ]],
    });
  }

  logIn(): void {
    this.authService.login({
      username: this.loginForm.get('userName').value,
      password: this.loginForm.get('userPass').value
    }).subscribe(
      () => this.router.navigate(['/dashboard']),
    );
  }

}
