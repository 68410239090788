import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {getRoundNumber} from '@app/services/helper-functions';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
    @Input() dataTable;
    @Output() starToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() volumeToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() sentimentToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    public _starToggle = true;
    public _volumeToggle = true;
    public _sentimentToggle = true;

    constructor() {
    }

    ngOnInit() {
    }

    public starClick() {
        this._starToggle = !this._starToggle;
        this.starToggle.emit(this._starToggle);
    }
    public volumeClick() {
        this._volumeToggle = !this._volumeToggle;
        this.volumeToggle.emit(this._volumeToggle);
    }
    public sentimentClick() {
        this._sentimentToggle = !this._sentimentToggle;
        this.sentimentToggle.emit(this._sentimentToggle);
    }
    getRoundValue(value, demical, mandatoryDemicalPoint, percent) {
        return getRoundNumber(value, demical, mandatoryDemicalPoint, percent);
    }

}
