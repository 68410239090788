import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isLoggedIn: boolean = !!sessionStorage.getItem('@TOKEN');

  constructor(private http: HttpClient, private router: Router) {}

  get isUserLoggedIn(): boolean {
    return this.isLoggedIn;
  }
  // TODO
  login(creds: { [key: string]: string }): Observable<any> {
    return this.loginUser(creds).pipe(
      map((data: any) => {
        sessionStorage.setItem('@TOKEN', data.access_token);
        this.markUserAsLoggedIn();
        this.router.navigate(['dashboard']);
      })
    );
  }
  // TODO
  sendRequest(creds: { [key: string]: string }): Observable<any> {
    return this.sendAccessRequest(creds).pipe(
      map((data: any) => {
        sessionStorage.setItem('@TOKEN', data.access_token);
        this.markUserAsLoggedIn();
        this.router.navigate(['dashboard']);
      })
    );
  }
  // TODO
  logout(): void {
    sessionStorage.clear();
    this.router.navigate(['login']);
  }

  private markUserAsLoggedIn(): void {
    this.isLoggedIn = !!sessionStorage.getItem('@TOKEN');
  }
  // TODO
  private loginUser(creds: { [key: string]: string }): Observable<any> {
    return this.http.post('oauth/v2/token', creds);
  }
  // TODO
  private sendAccessRequest(creds: { [key: string]: string }): Observable<any> {
    return this.http.post('request', creds);
  }
}
