export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June',
                      'July', 'August', 'September', 'October', 'November', 'December'];
export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


export function getRoundNumber(value: number | string,
                              decimals: number = 2,
                              mandatoryDemicalPoint: boolean = false,
                              percent: boolean = false) {
  if (+value === 0) { return 0; }

  if (percent) {
    value = Math.round(+value * Math.pow(10, decimals + 1)) / Math.pow(10, decimals - 1);
  } else {
    value = Math.round(+value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }

  return mandatoryDemicalPoint ? value.toFixed(decimals) : value;
}

export function getNecessaryFilters(data, nessFiltersArr) {
  let arr: Array<any>;
  const tmpArr: any[] = [];

  nessFiltersArr.forEach(item => {
    switch (item) {
      case 'brands':
        __addNecessFilter('brands', 'Brands');
        break;
      case 'categories':
        __addNecessFilter('categories', 'Categories');
        break;
      case 'dataSource':
        __addNecessFilter('dataSource', 'Data sources');
        break;
      case 'periods':
        __addNecessFilter('periods', 'Time Period');
        break;
      default:
        break;
    }
  });

  function __addNecessFilter(category: string, title: string) {
    if (nessFiltersArr.includes(category) && data[category].length > 0) {
      arr = [];

      if (category === 'periods') {
        data[category].forEach(item => {
          arr.push({
            title: item.description,
            id: item.id
          });
        });
      } else {
        data[category].forEach(item => {
          arr.push(item);
        });
      }

      tmpArr.push({
        title: title,
        filters: arr
      });
    }
  }

  return tmpArr;
}


export function getCurTimePeriod(timePeriodId: number = 4) {
  console.log('gettting current time period:', timePeriodId);
  const curDate = new Date();
  let curPeriod;
  const curRangePeriod = [];

  switch (timePeriodId) {
    case 0:
      curPeriod = 7;
      break;
    case 1:
      curPeriod = 30;
      break;
    case 2:
      curPeriod = 90;
      break;
    case 3:
      curPeriod = 356;
      break;
    case 4:
      curPeriod = 1460;
      break;
  }

  switch (curPeriod) {
      case 1460:
    case 730:
    case 356:
    case 90:
      let range = timePeriodId === 3 || timePeriodId === 4 ? 12 : 3;
      const dateForRange = new Date();

      if (range === 3) {
        dateForRange.setDate(curDate.getDate() - 90);
        range = __diffMonths(dateForRange, curDate);
      }

      for (let i = 0; i < range; i++) {
        const localDate = new Date();
        localDate.setMonth(curDate.getMonth() - i);

        const curYear = (localDate.getFullYear() + '').slice(-2);

        curRangePeriod.push({
          month: MONTHS[localDate.getMonth()],
          year: curYear,
          date: localDate
        });
      }
      break;
    case 7:
      for (let i = 0; i < 7; i++) {
        const localDate = new Date();
        localDate.setDate(curDate.getDate() - i);

        const curDay = localDate.getDay();

        curRangePeriod.push({
          day: DAYS[curDay],
          number: localDate.getDate(),
          date: localDate
        });
      }
      break;
    default:
      for (let i = 0; i < 30; i++) {
        const localDate = new Date();
        localDate.setDate(curDate.getDate() - i);

        const curMonth = localDate.getMonth();

        curRangePeriod.push({
          number: localDate.getDate(),
          month: MONTHS[localDate.getMonth()].slice(0, 3),
          date: localDate
        });
      }
  }

  return curRangePeriod.reverse();
}

export function sortArrayByName(arr, param = '') {
  arr.sort(function (a, b) {
    const textA = param ? a[param].toUpperCase() : a.toUpperCase();
    const textB = param ? b[param].toUpperCase() : b.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
}

export function sortArrayByCount(arr, param = '') {
  arr.sort(function (a, b) {
    const paramA = param ? a[param].length : a.length;
    const paramB = param ? b[param].length : b.length;
    return (paramA > paramB) ? -1 : (paramA < paramB) ? 1 : 0;
  });
}


function __diffMonths(dt2, dt1) {
  const tempDate = new Date(dt2);
  let monthCount = 0;
  /* tslint:disable */
  while ((tempDate.getMonth() + '' + tempDate.getFullYear()) != (dt1.getMonth() + '' + dt1.getFullYear())) {
    monthCount++;
    tempDate.setMonth(tempDate.getMonth() + 1);
  }
  /* tslint:enable */
  return monthCount + 1;
}
