import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import {AnalysisKeyService} from '@app/services/analysis-key.service';

@Injectable({
  providedIn: 'root'
})
export class BrandsService {
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token'
    })
  };

  constructor(private http: HttpClient,
              private analysisKeyService: AnalysisKeyService) {}

  getBrands(name: string = this.analysisKeyService.key(), curCategory: string = ''): Observable<any> {
    return this.http.get(curCategory.length ? `snap/brands/${name}/${curCategory}` : `snap/brands/${name}`);
  }


  getGraphsInfoPerBrand(name: string = this.analysisKeyService.key()): Observable<any> {
    return forkJoin(
      this.http.get(`snap/reviewVolumePerCategory/${name}`),
      this.http.get(`snap/keywordFrequency/${name}`),
      this.http.get(`snap/sentimentScore/${name}`)
    );
  }

  getFilteredGraphsInfoPerBrand(name: string = this.analysisKeyService.key(), params = {}): Observable<any> {
    const tmpObj = {
      keyName: name,
      ...params
    };
    return forkJoin(
      this.http.post(`snap/filteredReviewVolumePerCategory`, tmpObj),
      this.http.post(`snap/filteredKeywordFrequency`, tmpObj),
      this.http.post(`snap/filteredSentimentScore`, tmpObj)
    );
  }

  getNessFiltersPerBrand(name: string = this.analysisKeyService.key(), curCategory: string = ''): Observable<any> {
    return forkJoin(
      this.http.get(curCategory ? `snap/categories/${name}/${curCategory}` : `snap/categories/${name}`),
      this.http.get(`snap/summary/${name}`), // TODO: need to be replaced by dataSource
      // this.http.get(`snap/dataSource/${name}`)
      this.http.get(`snap/periods`)
    );
  }
}
