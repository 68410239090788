import { Component } from '@angular/core';
import {AuthService} from '@app/services/auth/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
    styleUrls: ['./main-nav.component.scss']
})
export class MainMenuComponent {

  constructor(private authService: AuthService, public router: Router) { }

  logOut() {
    this.authService.logout();
  }

}
